import { Component, OnInit } from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { Nora } from 'primeng/themes/nora';
import { definePreset } from 'primeng/themes';
import { map, merge } from 'rxjs';
import { PreloaderService } from './core/services/preloader.service';
import { CoordinateService } from './core/services/coordinates/coordinate.service';
import { HttpStatusCodeApiService } from './core/services/api/http-status-code-api.service';
import { ErrorApiResponse } from './core/interfaces/api';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	providers: [MessageService]
})
export class AppComponent implements OnInit {
	constructor(
		public preloaderService: PreloaderService,
		private httpStatusCodeApiService: HttpStatusCodeApiService,
		private messageService: MessageService,
		private coordinateService: CoordinateService,
		private config: PrimeNGConfig,
	) {
		coordinateService.init();
		const myPreset = definePreset(Nora, {
			semantic: {
				primary: {
					50: '#3e7efd',
					100: '#3e7efd',
					200: '#3e7efd',
					300: '#3e7efd',
					400: '#3e7efd',
					500: '#3e7efd',
					600: '#3e7efd',
					700: '#3e7efd',
					800: '#3e7efd',
					900: '#3e7efd',
					950: '#3e7efd',
				},
			},
		});
		this.config.theme.set({
			preset: myPreset,
			options: {
				prefix: 'p',
				darkModeSelector: '',
				cssLayer: false,
			},
		});
	}

	public ngOnInit(): void {
		const {errorBody$, successBody$, infoBody$} = this.httpStatusCodeApiService;
		merge(
			successBody$.pipe(map((value) => ({ type: 'success', value }))),
			infoBody$.pipe(map((value) => ({ type: 'info', value }))),
			errorBody$.pipe(map((value) => ({ type: 'error', value })))
		).subscribe({
			next: (result) => {
				this.messageService.clear();
				if (result.type === 'success') {
					this.showSuccess(result.value as string);
					return;
				}

				if (result.type === 'info') {
					this.showInfo(result.value as string);
					return;
				}

				this.showError(result.value as ErrorApiResponse);
			}
		});
	}

	private showError(error: ErrorApiResponse): void {
		this.messageService.add({ severity: 'error', summary: 'Error', detail: error.message });
	}

	private showSuccess(message: string): void {
		this.messageService.add({severity: 'success', summary: 'Success', detail: message});
	}

	private showInfo(message: string): void {
		this.messageService.add({ severity: 'info', summary: 'Info', detail: message });
	}
}
